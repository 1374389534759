(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropUserController', TropUserController);

    TropUserController.$inject = ['$state', 'TropUser', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TropUserController($state, TropUser, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;

        vm.filterSearch = pagingParams.search;
        vm.search = search;
        vm.pressEnter = pressEnter;

        loadAll();

        function loadAll () {
            TropUser.query({
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort(),
                search: vm.filterSearch
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropUsers = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.filterSearch
            });
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function search(){
            vm.transition();
        }
    }
})();
