(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('GalleryController', GalleryController);

    GalleryController.$inject = ['$state', 'DataUtils', 'Gallery', 'ParseLinks', 'AlertService','$window', 'paginationConstants', 'pagingParams'];

    function GalleryController($state, DataUtils, Gallery, ParseLinks, AlertService, $window,paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll () {
            Gallery.query({
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.galleries = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.openUrl=function(url){
        	$window.open(url, '_blank', 'height=300,width=400');
        }
    }
})();
