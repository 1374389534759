(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventDetailController', TropEventDetailController);

    TropEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TropEvent'];

    function TropEventDetailController($scope, $rootScope, $stateParams, previousState, entity, TropEvent) {
        var vm = this;

        vm.tropEvent = entity;
        vm.previousState = previousState.name;
        vm.createSportEvent=createSportEvent;
		vm.reindex = reindex;
        vm.prefilter=prefilter;
        vm.refuse=refuse;
        vm.publish=publish;

        var unsubscribe = $rootScope.$on('tropadminApp:tropEventUpdate', function(event, result) {
            vm.tropEvent = result;
        });

        function createSportEvent(){
            TropEvent.createSportEvent({id: vm.tropEvent.id}, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess () {
                alert('Sport Event Created');
                location.reload();
        }

        function onSaveError () {
            alert('Sport Event Creation Failed');
            location.reload();
        }

        function onRefuseSuccess () {
            alert('Event Refused');
            location.reload();
        }

        function onPublishSuccess () {
            alert('Event Published');
            location.reload();
        }

        function onGlobalError () {
            alert('Error');
            location.reload();
        }

		function reindex(tropEventId){
			TropEvent.reindex({id: tropEventId });
		}

        function prefilter(data) {
            vm.filters = data;
            vm.transition();
        }

        function refuse(tropEvent){
            vm.isSaving = true;
            TropEvent.refuse({id: tropEvent.id }, onRefuseSuccess, onGlobalError);
        }

        function publish(tropEvent){
            var filters=vm.filters;
            vm.isSaving = true;
            TropEvent.publish({id: tropEvent.id }, function(result){
                vm.filters=filters;
                vm.isSaving = false;
                onPublishSuccess();
            }, onGlobalError);

        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
