(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventLoaderLogController', TropEventLoaderLogController);

    TropEventLoaderLogController.$inject = ['$scope', '$state', 'TropEventLoaderLog','ParserLog','ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function TropEventLoaderLogController ($scope, $state, TropEventLoaderLog,ParserLog, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.parsers = ParserLog.query();


        vm.filterParserName = pagingParams.parserName;
        vm.filterParserCategorie = pagingParams.parserCategorie;

        loadAll();

        function loadAll () {
            TropEventLoaderLog.query({
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort(),
                parserName: vm.filterParserName,
                parserCategorie: vm.filterParserCategorie,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'date') {
                    result.push('date');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropEventLoaderLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                parserName: vm.filterParserName,
                parserCategorie: vm.filterParserCategorie,
            });
        }
    }
})();
