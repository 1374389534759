(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('AuditController', AuditController);

    AuditController.$inject = ['$scope', '$state', 'Audit', 'AuditsService', 'ParseLinks','DateUtils', 'AlertService', 'pagingParams', 'paginationConstants'];

    function AuditController ($scope, $state, Audit, AuditsService, ParseLinks, DateUtils, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.datePickers = {};
        vm.auditSelected = [];

        var suffixTime='T00:00:00.000';

        vm.filters={
        	date:pagingParams.dateFrom!=null ? pagingParams.dateTo!=null ? [pagingParams.dateFrom+suffixTime,pagingParams.dateTo+suffixTime] : pagingParams.dateFrom+suffixTime : null,
        	dateTo:pagingParams.dateTo,
        	dateFrom:pagingParams.dateFrom,
        	user:pagingParams.user,
        	level:pagingParams.level,
        	category:pagingParams.category,
        	action:pagingParams.action,
        	message:pagingParams.message,
        }

        loadAll();

        function loadAll () {
            Audit.query(angular.extend({
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            },vm.filters), onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.audits = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current,angular.extend({
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            },vm.filters));
        }

        vm.datePickers.dateFrom = false;
        vm.datePickers.dateTo = false;

        vm.openCalendar=function(date) {
            vm.datePickers[date] = true;
        }

        vm.classFromLevel=function(level){
        	if(level=='ERROR'){
        		level='DANGER';
        	}
        	return level.toLowerCase();
        }

        vm.selectAll = function () {
            if (vm.auditSelected.length === vm.audits.length) {
                vm.auditSelected = [];
            } else if (vm.auditSelected.length === 0 || vm.auditSelected.length > 0) {
                vm.auditSelected = vm.audits.slice(0);
            }
        }

        vm.selectThis = function (audit) {
            if (vm.auditSelected.includes(audit)) {
                vm.auditSelected.splice(vm.auditSelected.indexOf(audit), 1);
            } else {
                vm.auditSelected.push(audit);
            }
        }

        vm.clickDropdown = function ($event) {
            var dropdown = angular.element($event.target).parent().find('ul.dropdown-menu');
            if (dropdown.css('display') === 'none') {
                dropdown.css('display', 'initial');
            } else {
                dropdown.css('display', 'none');
            }
        }

        vm.deleteSelected = function () {
            if (vm.auditSelected.length > 0) {
                if (vm.auditSelected.length === 1) {
                    Audit.delete({id: vm.auditSelected[0].id});
                } else {
                    AuditsService.deleteMultiple(vm.auditSelected.map(function (audit) {
                        return audit.id;
                    }));
                }
                vm.auditSelected = [];
                loadAll();
            }
        }
    }
})();
