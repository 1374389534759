(function() {
    'use strict';

    var nbElementPerPageSelect = {
        template: '<div class="pagination-sm">' +
            '<label for="nbElementPerPage">' +
                'Items per page:'
            + '</label>' +
            '<select name="nbElementPerPage" id="nbElementPerPage" ng-model="$ctrl.itemsPerPage" ng-change="$ctrl.onChange()">' +
            '<option ng-selected="{{$ctrl.itemsPerPage == 20}}" value="20">20</option>' +
            '<option ng-selected="{{$ctrl.itemsPerPage == 50}}" value="50">50</option>' +
            '<option ng-selected="{{$ctrl.itemsPerPage == 100}}" value="100">100</option>' +
            '<option ng-selected="{{$ctrl.itemsPerPage == 200}}" value="200">200</option>' +
            '<option ng-selected="{{$ctrl.itemsPerPage == 500}}" value="500">500</option>' +
            '</select>' +
        '</div>',
        bindings: {
            itemsPerPage: '=',
            onChange: '&'
        }
    };

    angular
        .module('tropadminApp')
        .component('nbElementPerPageSelect', nbElementPerPageSelect);
})();
