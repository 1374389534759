(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .filter('formatPriceCentime', formatPriceCentime);

	function formatPriceCentime(){
		
        return formatPriceCentime;

        function formatPriceCentime (priceCentimes) {
			var euros=(priceCentimes/100).toFixed(2);
			return ''+euros;
		}
	}

})();
