(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventLoaderDialogController', TropEventLoaderDialogController);

    TropEventLoaderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TropEventLoader','GoogleMap'];

    function TropEventLoaderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TropEventLoader,GoogleMap) {
        var vm = this;

        vm.tropEventLoader = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.valid = valid;
        vm.refuse = refuse;
        vm.checkAddress = checkAddress;
        vm.next=next;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        

        vm.isShowDetails=vm.tropEventLoader.error;//On ouvre les details si y'a des erreurs
       
        vm.showDetails=function(){
        	vm.isShowDetails=true;
        }

        vm.isShowTextOriginal=false
        vm.showTextOriginal=function(){
        	vm.isShowTextOriginal=true;
        }
        
        function save () {
            vm.isSaving = true;
            if (vm.tropEventLoader.id !== null) {
                TropEventLoader.update(vm.tropEventLoader, onSaveSuccess, onSaveError);
            } else {
                TropEventLoader.save(vm.tropEventLoader, onSaveSuccess, onSaveError);
            }
        }
        
        function valid(){
        	 vm.isSaving = true;
        	 vm.tropEventLoader.error=false;
        	 vm.tropEventLoader.status='VALID';
             TropEventLoader.update(vm.tropEventLoader, onSaveSuccess, onSaveError);
        }
        
        function refuse(){
	       	 vm.isSaving = true;
        	 vm.tropEventLoader.error=false;
	       	 vm.tropEventLoader.status='REFUSED';
	            TropEventLoader.update(vm.tropEventLoader, onSaveSuccess, onSaveError);
	    }

        function onSaveSuccess (result) {
            $scope.$emit('tropadminApp:tropEventLoaderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function next(){
        	$uibModalInstance.close(vm.tropEventLoader);
        }

        vm.datePickerOpenStatus.dateFrom = false;
        vm.datePickerOpenStatus.dateTo = false;
        vm.datePickerOpenStatus.dateCreation = false;
        vm.datePickerOpenStatus.dateUpdated = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function checkAddress(){
        	GoogleMap.get({address : vm.tropEventLoader.address},function(data){
        		vm.tropEventLoader.city=data.city;
        	},function(errorJson){
        		if(errorJson.status==404){
        			alert("trop api not available");
        		}else{
        			alert(errorJson.data.message);
        		}
        	});
        }
        
        vm.hovers={}
        vm.hoverIn = function(name){
            vm.hovers[name] = true;
        };

        vm.hoverOut = function(name){
        	delete vm.hovers[name];
        };
    }
})();
