(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tropevent-duplicate', {
            parent: 'entity',
            url: '/tropevent-duplicate?page&itemsPerPage&sort&search&status&city',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropeventDuplicates'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tropevent-duplicate/tropevent-duplicates.html',
                    controller: 'TropeventDuplicateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                city: null,
                status: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        city: $stateParams.city,
                        status: $stateParams.status
                    };
                }],
            }
        })
        .state('tropevent-duplicate-detail.edit', {
            parent: 'tropevent-duplicate-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tropevent-duplicate/tropevent-duplicate-dialog.html',
                    controller: 'TropeventDuplicateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropeventDuplicate', function(TropeventDuplicate) {
                            return TropeventDuplicate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tropevent-duplicate.new', {
            parent: 'tropevent-duplicate',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tropevent-duplicate/tropevent-duplicate-dialog.html',
                    controller: 'TropeventDuplicateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                manageEvent: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tropevent-duplicate', null, { reload: 'tropevent-duplicate' });
                }, function() {
                    $state.go('tropevent-duplicate');
                });
            }]
        })
        .state('tropevent-duplicate.edit', {
            parent: 'tropevent-duplicate',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tropevent-duplicate/tropevent-duplicate-dialog.html',
                    controller: 'TropeventDuplicateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropeventDuplicate', function(TropeventDuplicate) {
                            return TropeventDuplicate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tropevent-duplicate', null, { reload: 'tropevent-duplicate' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tropevent-duplicate.delete', {
            parent: 'tropevent-duplicate',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tropevent-duplicate/tropevent-duplicate-delete-dialog.html',
                    controller: 'TropeventDuplicateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TropeventDuplicate', function(TropeventDuplicate) {
                            return TropeventDuplicate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tropevent-duplicate', null, { reload: 'tropevent-duplicate' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
