(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventMailController',TropEventMailController);

    TropEventMailController.$inject = ['$uibModalInstance', 'entity', 'TropEvent'];

    function TropEventMailController($uibModalInstance, entity, TropEvent) {
        var vm = this;

        vm.tropEvent = entity;
        vm.clear = clear;
        vm.mail = mail;
        vm.bookingsMail = [];

        mail(vm.tropEvent);
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function mail(tropEvent) {
            console.log(tropEvent)
            TropEvent.mails({eventId: tropEvent.id}, function (result) {
                vm.bookingsMail = result;
                console.log(vm.bookingsMail)
            });
        }
    }
})();

