(function () {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('messaging-mail', {
                parent: 'entity',
                url: '/messaging-mail?page&itemsPerPage&sort&search&contains&mailStatus&mailType&dateStart&dateEnd',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'MessagingMail'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/messaging-mail/messaging-mail.html',
                        controller: 'MessagingMailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    itemsPerPage: {
                        value: '20',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    contains: null,
                    dateStart: null,
                    dateEnd: null,
                    mailStatus: null,
                    mailType: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            itemsPerPage: $stateParams.itemsPerPage,
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            dateStart: $stateParams.dateStart,
                            dateEnd: $stateParams.dateEnd,
                            search: $stateParams.search,
                            contains: $stateParams.contains,
                            mailStatus: $stateParams.mailStatus,
                            mailType: $stateParams.mailType
                        };
                    }],
                }
            })
            .state('messaging-mail-detail', {
                parent: 'messaging-mail',
                url: '/messaging-mail/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'MessagingMail'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/messaging-mail/messaging-mail-detail.html',
                        controller: 'MessagingMailDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'MessagingMail', function ($stateParams, MessagingMail) {
                        return MessagingMail.get({id: $stateParams.id});
                    }],
                    previousState: ['$state', function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'messaging-mail',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
    }
})();
