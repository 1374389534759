(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropEvent', TropEvent);

    TropEvent.$inject = ['$resource', 'DateUtils'];

    function TropEvent ($resource, DateUtils) {
        var resourceUrl =  'tropevent-api-v1/admin/events/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for(var i=0;i<data.dates.length;i++){
                        	data.dates[i].dateFrom = DateUtils.convertLocalDateFromServer(data.dates[i].dateFrom);
                            data.dates[i].dateTo = DateUtils.convertLocalDateFromServer(data.dates[i].dateTo);
                        }
                        data.dateCreation = DateUtils.convertDateTimeFromServer(data.dateCreation);
                        if(data.fields){
	                        data.fields= Object.keys(data.fields).map(function(key) {
	                            return {"key" : key, "value" : data.fields[key] }
	                        });
                        }
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    for(var i=0;i<data.dates.length;i++){
	                    copy.dates[i].dateFrom = DateUtils.convertLocalDateToServer(copy.dates[i].dateFrom);
	                    copy.dates[i].dateTo = DateUtils.convertLocalDateToServer(copy.dates[i].dateTo);
                    }
                    if( typeof copy.tags === 'string' ) {
                    	copy.tags = copy.tags.split(',');
                    }
                    if( typeof copy.sportCategories === 'string' ) {
                    	copy.sportCategories = copy.sportCategories.split(',');
                    }
                    if( typeof copy.fields === 'object' && data.fields!=null) {
                    	copy.fields=  data.fields.reduce(function(map, obj) {
						    map[obj.key] = obj.value;
						    return map;
						}, {});
                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateFrom = DateUtils.convertLocalDateToServer(copy.dateFrom);
                    copy.dateTo = DateUtils.convertLocalDateToServer(copy.dateTo);
                    return angular.toJson(copy);
                }
            }
            ,'refreshYear': {
            	url:'tropevent-search-v1/admin/events/refreshYear',
            	params:{year: '@year'},
            	method: 'PUT'
            }
            ,'publish':{
            	url:'tropevent-api-v1/admin/events/:id/publish',
                params:{id: '@id'},
                method:'PUT',
                hasBody:false
            }
            ,'unpublish':{
            	url:'tropevent-api-v1/admin/events/:id/unpublish',
                params:{id: '@id'},
                method:'PUT',
                hasBody:false
            }
            ,'reindex':{
            	url:'tropevent-api-v1/admin/events/:id/reindex',
                params:{id: '@id'},
                method:'PUT',
                hasBody:false
            }
            ,'refuse':{
                url:'tropevent-api-v1/admin/events/:id/refused',
                params:{id: '@id'},
                method:'PUT',
                hasBody:false
            }
            ,'changeFeeConfig':{
            	url:'tropevent-api-v1/admin/events/:id/feeConfig',
                params:{id: '@id',feeConfig: '@feeConfig'},
                method:'PUT',
                hasBody:false
            },
            'cancelBookings':{
            	url:'tropevent-api-v1/admin/events/:id/cancelBookings',
                params:{id: '@id',refundType: '@refundType'},
                method:'PUT',
                hasBody:false
            },
            'bookingCloseToBookingPaid':{
                url:'tropevent-api-v1/admin/events/:id/bookingCloseToBookingPaid',
                params:{id: '@id'},
                method:'PUT',
                hasBody:false
            },
            'statBooking': {
                method: 'GET',
            	url:'tropevent-api-v1/admin/events/:id/stats',
                params:{id: '@id'}
            },
            'statsEvents': {
                method: 'GET',
                url:'tropevent-api-v1/admin/events/stats',
            },
            'statsEventsBySpace': {
                method: 'GET',
                url:'tropevent-api-v1/admin/events/:id/statsBySpace',
                params:{id: '@id'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'mails': {
                method: 'GET',
            	url:'tropevent-api-v1/admin/trop-event-bookings/mails',
                params:{eventId: '@eventId'},
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    console.log(data)
                    return data;
                }
            },
            'createSportEvent':{
                url:'tropevent-api-v1/admin/sport-event/:id/create',
                params:{id: '@id'},
                method:'POST',
                hasBody:false
            },
        });
    }
})();
