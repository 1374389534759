(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropUserDialogController', TropUserDialogController);

    TropUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TropUser'];

    function TropUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TropUser) {
        var vm = this;

        vm.tropUser = entity;
        vm.clear = clear;
        vm.save = save;

        vm.authorities = ["ROLE_USER","ROLE_WAIT_EMAIL","ROLE_DEVELOPPER"];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tropUser.id !== null) {
                TropUser.update(vm.tropUser, onSaveSuccess, onSaveError);
            } else {
                TropUser.save(vm.tropUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tropadminApp:tropUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
