(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventController', TropEventController);

    TropEventController.$inject = ['$scope', '$state', 'TropEvent', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function TropEventController ($scope, $state, TropEvent, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.tropEvents = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.datePickers = {};


        //functions
        vm.refreshYear = refreshYear;
        vm.publish=publish;
        vm.unpublish=unpublish;
        vm.pressEnter = pressEnter;
        vm.search = search;
        vm.prefilter=prefilter;
        vm.refuse=refuse;
        vm.bookingCloseToBookingPaid=bookingCloseToBookingPaid;


        vm.filters = {
            search: pagingParams.search,
        	city: pagingParams.city,
            spaceKeyWord: pagingParams.spaceKeyWord,
        	publish: pagingParams.publish ? pagingParams.publish : '',
        	needReview: pagingParams.needReview,
        	ignoreTropLoader: pagingParams.ignoreTropLoader,
        	booking: pagingParams.booking,
            status: pagingParams.status,
        	bookingActif: pagingParams.bookingActif,
        	dateTo:pagingParams.dateTo,
        	dateFrom:pagingParams.dateFrom,
        }

   	 	vm.isSaving = false;
        vm.statsEvents = null;

        TropEvent.statsEvents(function(data){
           vm.statsEvents=data;
        });


        loadAll();

        function loadAll () {
            TropEvent.query($.extend({}, vm.filters, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort(),
            }), onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropEvents = data;
                vm.page = pagingParams.page;
            }
        }

        function prefilter(data) {
            vm.filters = data;
            vm.transition();
        }
        function onError(error) {
       	 	vm.isSaving = false;
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
                $state.transitionTo($state.$current,$.extend({}, vm.filters, {
                    page: vm.page,
                    itemsPerPage: parseInt(vm.itemsPerPage),
                    sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
                }));
        }

        function refreshYear(){
        	var year = window.prompt("Year to refresh in Elastic");
        	if(!isNaN(year)){
        		TropEvent.refreshYear({ "year":year}, function(r){
            		alert('ok');
            	}, function(error) {
                    AlertService.error(error.data.message);
                });
        	}
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function search(){
            vm.transition();
        }


        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.transition();
        }

        function publish(tropEvent){
			 var filters=vm.filters;
	       	 vm.isSaving = true;
	       	 TropEvent.publish({id: tropEvent.id }, function(result){
				vm.filters=filters;
	            vm.isSaving = false;
	            loadAll();
	        }, onError);

       }

       function unpublish(tropEvent){
			var filters=vm.filters;
      	 	vm.isSaving = true;
	       	TropEvent.unpublish({id: tropEvent.id }, function(result){
				vm.filters=filters;
	            vm.isSaving = false;
	            loadAll();
	        }, onError);
	    }
        function refuse(tropEvent){
            vm.isSaving = true;
            TropEvent.refuse({id: tropEvent.id }, onSaveSuccess, onError);
        }

        function bookingCloseToBookingPaid(tropEvent){
            vm.isSaving = true;
            TropEvent.bookingCloseToBookingPaid({id: tropEvent.id}, onSaveSuccess,onError);
        }

       vm.datePickers.dateFrom = false;
       vm.datePickers.dateTo = false;

       vm.openCalendar=function(date) {
           vm.datePickers[date] = true;
       }

    }
})();
