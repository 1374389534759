(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('audit', {
            parent: 'entity',
            url: '/audit?page&itemsPerPage&sort&dateFrom&dateTo&user&level&category&message&action',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Audits'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/audit/audits.html',
                    controller: 'AuditController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                dateFrom: null,
                dateTo: null,
                user: null,
                category: null,
                level: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        dateFrom: $stateParams.dateFrom,
                        dateTo: $stateParams.dateTo,
                        user: $stateParams.user,
                        category: $stateParams.category,
                        action: $stateParams.action,
                        message: $stateParams.message,
                        level: $stateParams.level
                    };
                }],
            }
        })
        .state('audit-detail', {
            parent: 'entity',
            url: '/audit/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Audit'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/audit/audit-detail.html',
                    controller: 'AuditDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Audit', function($stateParams, Audit) {
                    return Audit.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'audit',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('audit.delete', {
            parent: 'audit',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/audit/audit-delete-dialog.html',
                    controller: 'AuditDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Audit', function(Audit) {
                            return Audit.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('audit', null, { reload: 'audit' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
