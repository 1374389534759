(function () {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('MessagingMailController', MessagingMailController);

    MessagingMailController.$inject = ['$state', 'MessagingMail','ParseLinks', 'pagingParams', 'paginationConstants'];

    function MessagingMailController($state, MessagingMail, ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;


        vm.filterSearch = pagingParams.search;
        vm.filterContains = pagingParams.contains;
        vm.filterStatus = pagingParams.mailStatus;
        vm.filterType = pagingParams.mailType;
        vm.dateEnd = pagingParams.dateEnd;
        vm.dateStart = pagingParams.dateStart;
        vm.datePickers = {};

        vm.search = search;
        vm.pressEnter = pressEnter;

        loadAll();

        function getMessagingMailFilter() {
            return {
                search: vm.filterSearch,
                contains: vm.filterContains,
                mailStatus: vm.filterStatus,
                mailType: vm.filterType,
                dateEnd: vm.dateEnd,
                dateStart: vm.dateStart,
            }

        }
        function loadAll() {
            var messagingMailFilter = getMessagingMailFilter();
            console.log(messagingMailFilter);
            MessagingMail.query($.extend({}, messagingMailFilter, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            }), onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.messagingMails = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function prefilter(data) {
            vm.filters = data;
            vm.transition();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        function transition() {
            var messagingMailFilter = getMessagingMailFilter();
            $state.transitionTo($state.$current, $.extend({}, messagingMailFilter, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            }));
        }
        vm.datePickers.createdDate = false;
        vm.datePickers.dateEnd = false;

        vm.openCalendar=function(date) {
            vm.datePickers[date] = true;
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function search(){
            vm.transition();
        }

    }
})();
