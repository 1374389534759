(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trop-event-loader-log', {
            parent: 'parser',
            url: '/trop-event-loader-log?page&itemsPerPage&sort&search&parserName&parserCategorie',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventLoaderLogs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-logs.html',
                    controller: 'TropEventLoaderLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                search: null,
                parserName:null,
                parserCategorie:null,
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        parserName: $stateParams.parserName,
                        parserCategorie: $stateParams.parserCategorie,
                    };
                }],
            }
        })
        .state('trop-event-loader-log-detail', {
            parent: 'parser',
            url: '/trop-event-loader-log/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventLoaderLog'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-log-detail.html',
                    controller: 'TropEventLoaderLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TropEventLoaderLog', function($stateParams, TropEventLoaderLog) {
                    return TropEventLoaderLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trop-event-loader-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trop-event-loader-log-detail.edit', {
            parent: 'trop-event-loader-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-log-dialog.html',
                    controller: 'TropEventLoaderLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventLoaderLog', function(TropEventLoaderLog) {
                            return TropEventLoaderLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-loader-log.new', {
            parent: 'trop-event-loader-log',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-log-dialog.html',
                    controller: 'TropEventLoaderLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                parserName: null,
                                parserCategorie: null,
                                date: null,
                                nbElements: null,
                                nbAdds: null,
                                nbDeletes: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('trop-event-loader-log', null, { reload: 'trop-event-loader-log' });
                }, function() {
                    $state.go('trop-event-loader-log');
                });
            }]
        })
        .state('trop-event-loader-log.edit', {
            parent: 'trop-event-loader-log',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-log-dialog.html',
                    controller: 'TropEventLoaderLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventLoaderLog', function(TropEventLoaderLog) {
                            return TropEventLoaderLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-loader-log', null, { reload: 'trop-event-loader-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-loader-log.delete', {
            parent: 'trop-event-loader-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader-log/trop-event-loader-log-delete-dialog.html',
                    controller: 'TropEventLoaderLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventLoaderLog', function(TropEventLoaderLog) {
                            return TropEventLoaderLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-loader-log', null, { reload: 'trop-event-loader-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
