(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService','StatService','$state'];

    function HomeController ($scope, Principal, LoginService, StatService, $state) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.toMessaginMail = toMessaginMail;
        vm.formatPrice = formatPrice;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        vm.days=null;

        getAccount().then(function(){
			if( vm.isAuthenticated()){
	       		refreshStat();
			}
        });

        function getAccount() {
           return Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }

		function refreshStat() {
            StatService.days(function(data){
        		vm.days=data;
        	});
        }

        function toMessaginMail(){
        	$state.go('messaging-mail');
        }

        function formatPrice(price){
            if (!isNaN(price)) {
                var euros = price / 100;
                return euros.toFixed(2).replace('.', ',') + ' €';
            }else{
                return null;
            }
        }
    }
})();
