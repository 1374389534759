(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('FormFieldsDetailController', FormFieldsDetailController);

    FormFieldsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FormFields'];

    function FormFieldsDetailController($scope, $rootScope, $stateParams, previousState, entity, FormFields) {
        var vm = this;

        vm.formFields = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('tropadminApp:formFieldsUpdate', function(event, result) {
            vm.formFields = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
