(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trop-event', {
            parent: 'entity',
            url: '/trop-event?page&itemsPerPage&sort&search&city&publish&needReview&ignoreTropLoader&booking&status&bookingActif&dateFrom&dateTo&spaceKeyWord',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEvents'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trop-event/trop-events.html',
                    controller: 'TropEventController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                city:null,
                spaceKeyWord:'',
                publish:'',
                needReview:'true',
                ignoreTropLoader:'true',
                booking:'',
                status:'',
                bookingActif: '',
                dateFrom: null,
                dateTo: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        city: $stateParams.city,
                        spaceKeyWord: $stateParams.spaceKeyWord,
                        publish: $stateParams.publish,
                        needReview: $stateParams.needReview=='true',
                        ignoreTropLoader: $stateParams.ignoreTropLoader=='true',
                        booking: $stateParams.booking,
                        status: $stateParams.status,
                        bookingActif: $stateParams.bookingActif,
                        dateFrom: $stateParams.dateFrom,
                        dateTo: $stateParams.dateTo
                    };
                }],
            }
        })
        .state('trop-event-detail', {
            parent: 'entity',
            url: '/trop-event/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEvent'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trop-event/trop-event-detail.html',
                    controller: 'TropEventDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TropEvent', function($stateParams, TropEvent) {
                    return TropEvent.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trop-event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trop-event-detail.edit', {
            parent: 'trop-event-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event/trop-event-dialog.html',
                    controller: 'TropEventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEvent', function(TropEvent) {
                            return TropEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event.edit', {
            parent: 'trop-event',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event/trop-event-dialog.html',
                    controller: 'TropEventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEvent', function(TropEvent) {
                            return TropEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event', null, { reload: 'trop-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event.ticketInfo', {
            parent: 'trop-event',
            url: '/{id}/ticketInfo',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event/trop-event-ticket-info.html',
                    controller: 'TropEventTicketInfoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEvent', function(TropEvent) {
                            return TropEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event', null, { reload: 'trop-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-detail.mail', {
            parent: 'trop-event-detail',
            url: '/detail/mail',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event/trop-event-mail.html',
                    controller: 'TropEventMailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['TropEvent', function(TropEvent) {
                            console.log(TropEvent.get({id : $stateParams.id}))
                            return TropEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                $state.go('trop-event', null, { reload: 'trop-event' });
            }, function() {
                $state.go('^');
            });
            }]
        })
        .state('trop-event.delete', {
            parent: 'trop-event',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event/trop-event-delete-dialog.html',
                    controller: 'TropEventDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TropEvent', function(TropEvent) {
                            console.log(TropEvent)
                            return TropEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event', null, { reload: 'trop-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('trop-event.deleteUser', {
        parent: 'trop-event',
        url: '/{id}/deleteUser',
        data: {
            authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/trop-user/trop-user-delete-dialog.html',
                controller: 'TropEventDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['TropEvent', function(TropEvent) {
                        return TropEvent.get({id : $stateParams.id}).$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('trop-event', null, { reload: 'trop-event' });
            }, function() {
                $state.go('^');
            });
        }]
    });
}

})();
