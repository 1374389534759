(function() {
    //'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventLoaderController', TropEventLoaderController);

    TropEventLoaderController.$inject = ['$scope', '$state', 'TropEventLoader', 'ParserLog','ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];


    function TropEventLoaderController($scope, $state, TropEventLoader, ParserLog,ParseLinks, AlertService, pagingParams, paginationConstants) {

    	var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.parsers = ParserLog.query();

        vm.search= search;
        vm.exportJson=exportJson;
        vm.restartError=restartError;
        vm.restartSyncImage=restartSyncImage;
        vm.deleteMultiple=deleteMultiple;

        vm.filterStatus = pagingParams.status;
        vm.filterSearch = pagingParams.search;
        vm.filterParserName = pagingParams.parserName;
        vm.filterParserCategorie = pagingParams.parserCategorie;
        vm.filterCity = pagingParams.city;
        vm.filterError = pagingParams.error=='true';
        vm.filterSyncException = pagingParams.syncException;

        vm.rafaleFonction=rafaleFonction;
        vm.enableRafale=pagingParams.rafale;
        vm.indexRafale=pagingParams.indexRafale || 0;

        if(vm.tropEventLoaders && vm.indexRafale>0){
        	rafaleFonction();
        }else{
        	loadAll();
        }

        function getTropEventLoaderFilter(){
        	return {
                search: vm.filterSearch,
                status: vm.filterStatus,
                parserName: vm.filterParserName,
                parserCategorie: vm.filterParserCategorie,
                city: vm.filterCity,
                error: vm.filterError,
                syncException: vm.filterSyncException
            };
        }

        function loadAll () {
        	var tropEventLoaderFilter=getTropEventLoaderFilter();

            TropEventLoader.query($.extend({}, tropEventLoaderFilter, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            }), onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropEventLoaders = data;
                vm.page = pagingParams.page;
                if(vm.enableRafale){
                	rafaleFonction();
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function search(){
        	vm.enableRafale=false;
            vm.transition();
        }

        function transition () {
        	var tropEventLoaderFilter=getTropEventLoaderFilter();

            $state.transitionTo($state.$current,$.extend({}, tropEventLoaderFilter, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            }));
        }

        function exportJson(){
        	if(isEmptyOrNull(vm.filterSearch)
        		|| isEmptyOrNull(vm.filterStatus)
        		|| isEmptyOrNull(vm.filterParserName)
        		|| isEmptyOrNull(vm.filterParserCategorie)
            	|| isEmptyOrNull(vm.filterCity)){

            	var tropEventLoaderFilter=getTropEventLoaderFilter();

	        	 TropEventLoader.query(tropEventLoaderFilter, onSuccess, onError);
	             function onSuccess(data, headers) {
	            	 saveToFileName(data,'export.json');
	             }
	             function onError(error) {
	                 AlertService.error(error.data.message);
	             }
        	}else{
                AlertService.error('please select criteria');
        	}
        }

        function isEmptyOrNull(t){
        	return t!=null && t!='';
        }

        function saveToFileName(data, filename) {

        	  if (!data) {
        	    console.error('No data');
        	    return;
        	  }

        	  if (!filename) {
        	    filename = 'download.json';
        	  }

        	  if (typeof data === 'object') {
        	    data = JSON.stringify(data, undefined, 2);
        	  }

        	  var blob = new Blob([data], {type: 'text/json'});

        	  // FOR IE:

        	  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        	      window.navigator.msSaveOrOpenBlob(blob, filename);
        	  }
        	  else{
        	      var e = document.createEvent('MouseEvents'),
        	          a = document.createElement('a');

        	      a.download = filename;
        	      a.href = window.URL.createObjectURL(blob);
        	      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
        	      e.initEvent('click', true, false, window,
        	          0, 0, 0, 0, 0, false, false, false, false, 0, null);
        	      a.dispatchEvent(e);
        	  }
        };


        // Fonctions liés à la validation en rafale

        function rafaleFonction(){
        	vm.enableRafale=true;
            //vm.indexRafale=0;
        	if(vm.indexRafale>vm.tropEventLoaders.length){
        		//Il faut recharge la page pour voir les élements suivants à valider
                $state.go('trop-event-loader', { rafale: '1',indexRafale:0 }, { reload: 'trop-event-loader'});
        	}else{
	            var tropEventLoader=vm.tropEventLoaders[vm.indexRafale];
	            $state.go('trop-event-loader.edit', {id:tropEventLoader.id,rafale:'1',indexRafale: vm.indexRafale});
        	}
        }

        function restartError(){
        	var tropEventLoaderFilter=getTropEventLoaderFilter();
        	TropEventLoader.restartError(tropEventLoaderFilter, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function restartSyncImage(){
        	var tropEventLoaderFilter=getTropEventLoaderFilter();
        	TropEventLoader.restartSyncImage(tropEventLoaderFilter, function(s){
        		transition();
        	}, function(error) {
                AlertService.error(error.data.message);
            });
        }

        function deleteMultiple(){
        	if(confirm("Change state all this events to DELETE ?")){
	        	var tropEventLoaderFilter=getTropEventLoaderFilter();
	        	TropEventLoader.deleteMultiple(tropEventLoaderFilter, function(s){
	        		loadAll();
	        	}, function(error) {
	                AlertService.error(error.data.message);
	            });
        	}
        }
    }
})();
