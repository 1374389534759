(function () {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropeventDuplicateController', TropeventDuplicateController);

    TropeventDuplicateController.$inject = ['$state', 'TropeventDuplicate', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TropeventDuplicateController($state, TropeventDuplicate, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;

        vm.search = search;
        vm.pressEnter = pressEnter;
        vm.prefilter = prefilter;

        vm.filters = {
            city: pagingParams.city,
            search: pagingParams.search,
            status: pagingParams.status || 'DEFAULT',
        }

        loadAll();

        function loadAll() {
            TropeventDuplicate.query($.extend({}, vm.filters, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort(),
            }), onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropeventDuplicates = data;
                vm.page = pagingParams.page;
            }


        }

        function prefilter(data) {
            vm.filters = data;
            vm.transition();
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, $.extend({}, vm.filters, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            }));
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function search() {
            vm.transition();
        }
    }
})();
