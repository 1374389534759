(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('Space', Space);

    Space.$inject = ['$resource'];

    function Space ($resource) {
        var resourceUrl =  'tropevent-api-v1/admin/spaces/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
            ,'generateSuperAdminSpaceToken':{
            	url:'tropevent-api-v1/admin/spaces/:id/generateSuperAdminSpaceToken'
                ,params:{id: '@id'}
                ,method:'POST'
                ,hasBody:false
            },
            'getAllSubscriptionOptionsType': {
                url: 'tropevent-api-v1/admin/spaces/allSubscriptionOptionsType',
                method: 'GET',
                isArray: true
            },
            'addSubscriptionOption': {
                url: 'tropevent-api-v1/admin/space/:id/addSubscriptionOption',
                params: {id: '@id'},
                method: 'PUT',
                hasBody: true
            },
            'deleteSubscriptionOption': {
                url: 'tropevent-api-v1/admin/space/:id/deleteSubscriptionOption',
                params: {id: '@id', type: '@type'},
                method: 'DELETE'
            }
        });
    }
})();
