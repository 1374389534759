(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventBookingDeleteController',TropEventBookingDeleteController);

    TropEventBookingDeleteController.$inject = ['$state', '$uibModalInstance', 'entity', 'TropEventBooking'];

    function TropEventBookingDeleteController($state, $uibModalInstance, entity, TropEventBooking) {
        var vm = this;

        vm.tropEventBooking = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

		vm.bookingTicketNumber=$state.params.ticketNumber;
		vm.bookingStatus='CANCELED_ORGANISER';
		vm.refundType='REFUND_TOTAL';
		vm.refundInCentimes=entity.totalPriceCentimes;
        vm.globalTicket=false;

		vm.changeBookingTicketNumber = changeBookingTicketNumber;
        vm.toggleGlobalTicket = toggleGlobalTicket;

		function changeBookingTicketNumber(){
			if(vm.bookingTicketNumber!=''){
				vm.refundType='REFUND_PARTIAL';
			}
		}

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            if (vm.globalTicket) {
                vm.tropEventBooking.tickets.forEach(function (ticket) {
                    TropEventBooking.cancel({id: id, bookingStatus:vm.bookingStatus, refundType: vm.refundType, refundInCentimes: vm.refundInCentimes, bookingTicketNumber: ticket.ticketNumber},
                        function () {
                            $uibModalInstance.close(true);
                        });
                });
            } else {
                TropEventBooking.cancel({id: id, bookingStatus:vm.bookingStatus, refundType: vm.refundType, refundInCentimes: vm.refundInCentimes, bookingTicketNumber: vm.bookingTicketNumber},
                    function () {
                        $uibModalInstance.close(true);
                    });
            }
        }

        function toggleGlobalTicket(){
            vm.globalTicket=!vm.globalTicket;
        }
    }
})();
