(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trop-event-loader', {
            parent: 'parser',
            url: '/trop-event-loader?page&itemsPerPage&sort&search&status&parserName&parserCategorie&city&error&syncException&rafale&indexRafale',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventLoaders'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loaders.html',
                    controller: 'TropEventLoaderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null,
                status: null,
                parserName:null,
                parserCategorie:null,
                city:null,
                error:null,
                syncException:null,
                rafale:'0',
                indexRafale:null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        status: $stateParams.status,
                        parserName: $stateParams.parserName,
                        parserCategorie: $stateParams.parserCategorie,
                        city: $stateParams.city,
                        error:$stateParams.error,
                        syncException:$stateParams.syncException,
                        rafale: $stateParams.rafale=='1',
                        indexRafale: $stateParams.indexRafal ? parseInt($stateParams.indexRafale) : 0
                    };
                }],
            }
        })
        .state('trop-event-loader-detail', {
            parent: 'parser',
            url: '/trop-event-loader/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventLoader'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loader-detail.html',
                    controller: 'TropEventLoaderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TropEventLoader', function($stateParams, TropEventLoader) {
                    return TropEventLoader.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trop-event-loader',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trop-event-loader-detail.edit', {
            parent: 'trop-event-loader-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loader-dialog.html',
                    controller: 'TropEventLoaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventLoader', function(TropEventLoader) {
                            return TropEventLoader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-loader.new', {
            parent: 'trop-event-loader',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loader-dialog.html',
                    controller: 'TropEventLoaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                parserName: null,
                                parserCategorie: null,
                                parserExterneId: null,
                                name: null,
                                organizerName: null,
                                summary: null,
                                description: null,
                                dateFrom: null,
                                timeFrom: null,
                                dateTo: null,
                                timeTo: null,
                                city: null,
                                location: null,
                                dateCreation: null,
                                dateUpdated: null,
                                status: null,
                                externEventId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('trop-event-loader', null, { reload: 'trop-event-loader' });
                }, function() {
                    $state.go('trop-event-loader');
                });
            }]
        })
        .state('trop-event-loader.edit', {
            parent: 'trop-event-loader',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loader-dialog.html',
                    controller: 'TropEventLoaderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventLoader', function(TropEventLoader) {
                            return TropEventLoader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                	if($stateParams.rafale=='1'){
                		$stateParams.indexRafale=parseInt($stateParams.indexRafale)+1;
                        $state.go('trop-event-loader', { rafale: '1',indexRafale:$stateParams.indexRafale }, { reload: 'trop-event-loader'});
                	}else{
                		//Refresh the list
                        $state.go('trop-event-loader', null, { reload: 'trop-event-loader' });
                	}
                }, function() {
                    $state.go('trop-event-loader',  { rafale: '0',indexRafale:null }, { reload: 'trop-event-loader' });
                });
            }]
        })
        .state('trop-event-loader.delete', {
            parent: 'trop-event-loader',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/trop-event-loader/trop-event-loader-delete-dialog.html',
                    controller: 'TropEventLoaderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventLoader', function(TropEventLoader) {
                            return TropEventLoader.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-loader', null, { reload: 'trop-event-loader' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
