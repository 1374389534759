(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('ParserLog', ParserLog);

    ParserLog.$inject = ['$resource', 'DateUtils'];

    function ParserLog ($resource, DateUtils) {
        var resourceUrl =  'tropevent-loader/admin/parser-info/:parserName';

        return $resource(resourceUrl,  {parserName: '@parserName'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastSuccess = DateUtils.convertDateTimeFromServer(data.lastSuccess);
                        data.lastFail = DateUtils.convertDateTimeFromServer(data.lastFail);
                        data.nextExecution = DateUtils.convertDateTimeFromServer(data.nextExecution);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
            ,'pause':{
            	url:'tropevent-loader/admin/parser-info/:parserName/pause'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'resume':{
            	url:'tropevent-loader/admin/parser-info/:parserName/resume'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'trigger':{
            	url:'tropevent-loader/admin/parser-info/:parserName/trigger'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'reset':{
            	url:'tropevent-loader/admin/parser-info/:parserName/reset'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'clear':{
            	url:'tropevent-loader/admin/parser-info/:parserName/clear'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'syncActif':{
            	url:'tropevent-loader/admin/parser-info/:parserName/syncActif'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'syncInactif':{
            	url:'tropevent-loader/admin/parser-info/:parserName/syncInactif'
            	,method:'PUT'
            	,hasBody:false
            }
        });
    }
})();