(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventSyncLogController', TropEventSyncLogController);

    TropEventSyncLogController.$inject = ['$scope', '$state', 'TropEventSyncLog', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function TropEventSyncLogController ($scope, $state, TropEventSyncLog, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;

        vm.status={};

        loadAll();
        refreshStatus();


        function loadAll() {
            TropEventSyncLog.query(function(result) {
                vm.tropEventSyncLogs = result;
            });
        }

        function refreshStatus(){
        	 vm.status=TropEventSyncLog.status();
        }

        function loadAll () {
        	TropEventSyncLog.query({
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort(),
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'date') {
                    result.push('date');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tropEventSyncLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }


        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        vm.clear = function(){
        	TropEventSyncLog.clear(function(){
        		loadAll();
            	refreshStatus();
        	});
        }

        vm.restartError = function(){
        	TropEventSyncLog.restartError({},function(){
        		loadAll();
            	refreshStatus();
        	})
        }
    }
})();
