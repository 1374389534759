(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('SpaceDeleteController',SpaceDeleteController);

    SpaceDeleteController.$inject = ['$uibModalInstance', 'entity', 'Space'];

    function SpaceDeleteController($uibModalInstance, entity, Space) {
        var vm = this;

        vm.space = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Space.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
