(function () {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('MessagingMailDeleteController', MessagingMailDeleteController);

    MessagingMailDeleteController.$inject = ['$uibModalInstance', 'entity', 'MessagingMail'];

    function MessagingMailDeleteController($uibModalInstance, entity, MessagingMail) {
        var vm = this;

        vm.messagingMail = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MessagingMail.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

