(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropEventLoader', TropEventLoader);

    TropEventLoader.$inject = ['$resource', 'DateUtils'];

    function TropEventLoader ($resource, DateUtils) {
        var resourceUrl =  'tropevent-loader/admin/trop-event-loaders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for(var i=0;i<data.dates.length;i++){
                        	data.dates[i].dateFrom = DateUtils.convertLocalDateFromServer(data.dates[i].dateFrom);
                            data.dates[i].dateTo = DateUtils.convertLocalDateFromServer(data.dates[i].dateTo);
                        }
                        
                        data.dateCreation = DateUtils.convertDateTimeFromServer(data.dateCreation);
                        data.dateUpdated = DateUtils.convertDateTimeFromServer(data.dateUpdated);
                        data.fields= Object.keys(data.fields).map(function(key) {
                            return {"key" : key, "value" : data.fields[key] }
                        });
                        data.warningValidation= data.warningValidation.join(",")
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    for(var i=0;i<data.dates.length;i++){
	                    copy.dates[i].dateFrom = DateUtils.convertLocalDateToServer(copy.dates[i].dateFrom);
	                    copy.dates[i].dateTo = DateUtils.convertLocalDateToServer(copy.dates[i].dateTo);
                    }
                    if(copy.warningValidation!=''){
                    	copy.warningValidation= copy.warningValidation.split(",");
                    }else{
                    	copy.warningValidation=null;
                    }

                    if( typeof copy.tags === 'string' ) {
                    	copy.tags = copy.tags.split(',');
                    }
                    if( typeof copy.sportCategories === 'string' ) {
                    	copy.sportCategories = copy.sportCategories.split(',');
                    }
                    if( typeof copy.fields === 'object' ) {
                    	copy.fields=  data.fields.reduce(function(map, obj) {
						    map[obj.key] = obj.value;
						    return map;
						}, {});
                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateFrom = DateUtils.convertLocalDateToServer(copy.dateFrom);
                    copy.dateTo = DateUtils.convertLocalDateToServer(copy.dateTo);
                    copy.tags = copy.tags.split(',');
                    copy.sportCategories = copy.sportCategories.split(',');
                    
                    return angular.toJson(copy);
                }
            }
            ,'status':{
            	url:'tropevent-loader/admin/trop-event-loaders/:id/status'
            	,params:{id: '@id',status:'@status'}
            	,method:'PUT'
            	,hasBody:false
            }
            ,'restartError': { 
            	url:'tropevent-loader/admin/trop-event-loaders/restartError',
            	method: 'PUT'
            }
            ,'restartSyncImage': { 
            	url:'tropevent-loader/admin/trop-event-loaders/restartSyncImage',
            	method: 'PUT'
            }
            ,'deleteMultiple': { 
            	url:'tropevent-loader/admin/trop-event-loaders/deleteMultiple',
            	method: 'PUT'
            },
        });
    }
})();
