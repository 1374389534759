(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventTicketInfoController', TropEventTicketInfoController);

    TropEventTicketInfoController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TropEvent','FeeConfig'];

    function TropEventTicketInfoController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TropEvent, FeeConfig) {
        var vm = this;

        vm.tropEvent = entity;
        vm.ticketInfo =  vm.tropEvent.ticketInfo;
        vm.clear = clear;
        vm.changeFeeConfig = changeFeeConfig;
        vm.showJson = false;
        vm.stats=null;
        vm.cancelAllBookings = cancelAllBookings;
        vm.feeConfigs = [];
        vm.formatPrice = formatPrice;

        TropEvent.statBooking({id: vm.tropEvent.id },function(stats){
        	vm.stats=stats;
        });

        FeeConfig.query({
            onlyEnable: true
        }, function(data){
            vm.feeConfigs = data;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function changeFeeConfig(){
	       	TropEvent.changeFeeConfig({id: vm.tropEvent.id, "feeConfig": vm.ticketInfo.feeConfigName }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function cancelAllBookings(){
        	var refundType = prompt("Please enter refund type 'REFUND_TOTAL' or 'REFUND_WITHOUT_FEES'", "");
        	if (refundType != null && (refundType=='REFUND_TOTAL' || refundType=='REFUND_WITHOUT_FEES')) {
        		TropEvent.cancelBookings({id: vm.tropEvent.id, 'refundType':refundType}, onSaveSuccess, onSaveError);
        	}
        }

        function formatPrice(price){
            const euros = price / 100;
            return euros.toFixed(2).replace('.', ',') + ' €';
        }
    }
})();
