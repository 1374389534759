(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventDialogController', TropEventDialogController);

    TropEventDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TropEvent'];

    function TropEventDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TropEvent) {
        var vm = this;

        vm.tropEvent = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.publish = publish;
        vm.unpublish = unpublish;
        vm.bookingCloseToBookingPaid = bookingCloseToBookingPaid;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function publish(){
        	 vm.isSaving = true;
        	 TropEvent.publish({id: vm.tropEvent.id }, onSaveSuccess, onSaveError);
        }
        
        function unpublish(){
	       	vm.isSaving = true;
	       	TropEvent.unpublish({id: vm.tropEvent.id }, onSaveSuccess, onSaveError);
	    }

        function save () {
            vm.isSaving = true;
            if (vm.tropEvent.id !== null) {
                TropEvent.update(vm.tropEvent, onSaveSuccess, onSaveError);
            } else {
                TropEvent.save(vm.tropEvent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tropadminApp:tropEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function bookingCloseToBookingPaid(){
            vm.isSaving = true;
            TropEvent.bookingCloseToBookingPaid({id: vm.tropEvent.id}, onSaveSuccess,onSaveError);
        }

        vm.datePickerOpenStatus.dateFrom = false;
        vm.datePickerOpenStatus.dateTo = false;
        vm.datePickerOpenStatus.dateCreation = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
