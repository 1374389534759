(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropeventDuplicateDialogController', TropeventDuplicateDialogController);

    TropeventDuplicateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TropeventDuplicate','TropEvent','TropEventLoader'];

    function TropeventDuplicateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TropeventDuplicate,TropEvent,TropEventLoader) {
        var vm = this;

        vm.tropeventDuplicate = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tropeventDuplicate.id !== null) {
                TropeventDuplicate.update(vm.tropeventDuplicate, onSaveSuccess, onSaveError);
            } else {
                TropeventDuplicate.save(vm.tropeventDuplicate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tropadminApp:tropeventDuplicateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.deleteEvent=function(event){
        	if(confirm("Do you want to remove : "+event.name)){
            	if(event.ownerId=='troploader'){
            		//Créé par le parser
            		//On doit supprimé l'object TropEventLoader
            		TropEventLoader.status({id: event.externId,status:'TO_DELETE'},function(){
                    	 vm.updateStatus('WAITING_DELETE_PARSER');
                    });
            	}else{
            		//On supprime l'object TropEvent
                    TropEvent.delete({id: id},function(){
                    	vm.clear();
                    });
            	}
        	}
        }
        
        vm.updateStatus=function(status){
        	TropeventDuplicate.status({id: vm.tropeventDuplicate.id,status:status},function(){
            	vm.clear();
        	});
        }

    }
})();
