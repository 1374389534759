(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('StatTropEventViewDeleteController',StatTropEventViewDeleteController);

    StatTropEventViewDeleteController.$inject = ['$uibModalInstance', 'entity', 'StatTropEventView'];

    function StatTropEventViewDeleteController($uibModalInstance, entity, StatTropEventView) {
        var vm = this;

        vm.statTropEventView = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            StatTropEventView.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
