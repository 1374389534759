(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventDeleteController',TropEventDeleteController);

    TropEventDeleteController.$inject = ['$uibModalInstance', 'entity', 'TropEvent', 'TropUser'];

    function TropEventDeleteController($uibModalInstance, entity, TropEvent, TropUser) {
        var vm = this;

        vm.tropEvent = entity;
        vm.ownerId = vm.tropEvent.ownerId;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.confirmDeleteUser = confirmDeleteUser;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function confirmDelete (id) {
            TropEvent.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
        function confirmDeleteUser (id) {
            TropUser.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
