(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropEventBooking', TropEventBooking);

    TropEventBooking.$inject = ['$resource', 'DateUtils'];

    function TropEventBooking ($resource, DateUtils) {
        var resourceUrl =  'tropevent-api-v1/admin/trop-event-bookings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.tickets = data.tickets.map(function(ticket){
                            ticket.lastUserUpdateDate = DateUtils.convertDateTimeFromServer(ticket.lastUserUpdateDate);
                            return ticket;
                        })
                    }
                    return data;
                }
            },
            'count': {
                method: 'GET',
                url:'tropevent-api-v1/admin/trop-event-bookings/count-per-user',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
                },
            'update': { method:'PUT' },
            'cancel':{
            	url:'tropevent-api-v1/admin/trop-event-bookings/:id/cancel'
                ,params:{id: '@id',bookingStatus: '@bookingStatus',refundType:'@refundType',refundInCentimes:'@refundInCentimes', 'bookingTicketNumber': '@bookingTicketNumber'}
                ,method:'PUT'
                ,hasBody:false
            },
            'comment':{
            	url:'tropevent-api-v1/admin/trop-event-bookings/:id/comment'
                ,params:{id: '@id'}
                ,method:'PUT'
            },
            'sendEmail':{
                url:'tropevent-api-v1/admin/trop-event-bookings/:id/send-email',
                method: 'GET',
                params:{id: '@id'}
            }
        });
    }
})();
