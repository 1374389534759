(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('parser-log', {
            parent: 'parser',
            url: '/parser-log?page&itemsPerPage&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ParserLogs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/parser-log/parser-logs.html',
                    controller: 'ParserLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('parser-log-detail', {
            parent: 'parser',
            url: '/parser-log/{parserName}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ParserLog'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities-parser/parser-log/parser-log-detail.html',
                    controller: 'ParserLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ParserLog', function($stateParams, ParserLog) {
                    return ParserLog.get({parserName : $stateParams.parserName}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'parser-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('parser-log-detail.edit', {
            parent: 'parser-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/parser-log/parser-log-dialog.html',
                    controller: 'ParserLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ParserLog', function(ParserLog) {
                            return ParserLog.get({parserName : $stateParams.parserName}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('parser-log.new', {
            parent: 'parser-log',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/parser-log/parser-log-dialog.html',
                    controller: 'ParserLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                parserName: null,
                                lastSuccess: null,
                                lastFail: null,
                                nbFails: null,
                                nbSuccess: null,
                                lastDuration: null,
                                lastError: null,
                                success: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('parser-log', null, { reload: 'parser-log' });
                }, function() {
                    $state.go('parser-log');
                });
            }]
        })
        .state('parser-log.edit', {
            parent: 'parser-log',
            url: '/{parserName}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/parser-log/parser-log-dialog.html',
                    controller: 'ParserLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ParserLog', function(ParserLog) {
                            return ParserLog.get({parserName : $stateParams.parserName}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('parser-log', null, { reload: 'parser-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('parser-log.delete', {
            parent: 'parser-log',
            url: '/{parserName}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities-parser/parser-log/parser-log-delete-dialog.html',
                    controller: 'ParserLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ParserLog', function(ParserLog) {
                            return ParserLog.get({parserName : $stateParams.parserName}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('parser-log', null, { reload: 'parser-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('parser-log.pause', {
            parent: 'parser-log',
            url: '/{parserName}/pause',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', 'ParserLog', function($stateParams, $state, ParserLog) {
            	ParserLog.pause({parserName : $stateParams.parserName},function(){
                	$state.go('parser-log', null, { reload: 'parser-log' });
            	});
            }]
        })
        .state('parser-log.resume', {
            parent: 'parser-log',
            url: '/{parserName}/resume',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', 'ParserLog', function($stateParams, $state, ParserLog) {
            	ParserLog.resume({parserName : $stateParams.parserName},function(){
                	$state.go('parser-log', null, { reload: 'parser-log' });
            	});
            }]
        })
        .state('parser-log.trigger', {
            parent: 'parser-log',
            url: '/{parserName}/trigger',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', 'ParserLog', function($stateParams, $state, ParserLog) {
            	ParserLog.trigger({parserName : $stateParams.parserName},function(){
                	$state.go('parser-log', null, { reload: 'parser-log' });
            	});
            }]
        })
        .state('parser-log.reset', {
            parent: 'parser-log',
            url: '/{parserName}/reset',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', 'ParserLog', function($stateParams, $state, ParserLog) {
            	ParserLog.reset({parserName : $stateParams.parserName},function(){
                	$state.go('parser-log', null, { reload: 'parser-log' });
            	});
            }]
        });
    }

})();
