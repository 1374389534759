(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropeventDuplicate', TropeventDuplicate);

    TropeventDuplicate.$inject = ['$resource'];

    function TropeventDuplicate ($resource) {
        var resourceUrl =  'tropevent-api-v1/admin/tropevent-duplicates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
            ,'status':{
            	url:'tropevent-api-v1/admin/tropevent-duplicates/:id/status'
            	,params:{id: '@id',status:'@status'}
            	,method:'PUT'
            	,hasBody:false
            }
        });
    }
})();
