(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trop-event-booking', {
            parent: 'entity',
            url: '/trop-event-booking?page&itemsPerPage&sort&search&bookingStatus&mailStatus&ticketStatus',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventBookings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trop-event-booking/trop-event-bookings.html',
                    controller: 'TropEventBookingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,
                bookingStatus:null,
                mailStatus: null,
                ticketStatus: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        bookingStatus: $stateParams.bookingStatus,
                        mailStatus: $stateParams.mailStatus,
                        ticketStatus: $stateParams.ticketStatus
                    };
                }],
            }
        })
        .state('trop-event-booking-detail', {
            parent: 'trop-event-booking',
            url: '/trop-event-booking/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'TropEventBooking'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-detail.html',
                    controller: 'TropEventBookingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'TropEventBooking', function($stateParams, TropEventBooking) {
                    return TropEventBooking.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trop-event-booking',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trop-event-booking-detail.edit', {
            parent: 'trop-event-booking-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-dialog.html',
                    controller: 'TropEventBookingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-booking.new', {
            parent: 'trop-event-booking',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-dialog.html',
                    controller: 'TropEventBookingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                userId: null,
                                eventId: null,
                                eventName: null,
                                date: null,
                                numberParticipants: null,
                                createdDate: null,
                                paymentId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking', null, { reload: 'trop-event-booking' });
                }, function() {
                    $state.go('trop-event-booking');
                });
            }]
        })
        .state('trop-event-booking.edit', {
            parent: 'trop-event-booking',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-dialog.html',
                    controller: 'TropEventBookingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking', null, { reload: 'trop-event-booking' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-booking.delete', {
            parent: 'trop-event-booking',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-delete-dialog.html',
                    controller: 'TropEventBookingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking', null, { reload: 'trop-event-booking' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-booking.comment', {
            parent: 'trop-event-booking',
            url: '/{id}/comment',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-comment.html',
                    controller: 'TropEventBookingCommentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking', null, { reload: 'trop-event-booking' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-booking-detail.comment', {
            parent: 'trop-event-booking-detail',
            url: '/{id}/comment',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-comment.html',
                    controller: 'TropEventBookingCommentController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking-detail', null, { reload: 'trop-event-booking-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trop-event-booking-detail.delete', {
            parent: 'trop-event-booking-detail',
            url: '/{id}/delete/{ticketNumber}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trop-event-booking/trop-event-booking-delete-dialog.html',
                    controller: 'TropEventBookingDeleteController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['TropEventBooking', function(TropEventBooking) {
                            console.log('TropEventBookingDeleteController', $stateParams);
                            return TropEventBooking.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trop-event-booking-detail', null, { reload: 'trop-event-booking-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
