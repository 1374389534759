(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('SpaceController', SpaceController);

    SpaceController.$inject = ['$scope', '$state', 'Space', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function SpaceController ($scope, $state, Space, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.spaces = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = pagingParams.itemsPerPage === undefined ? paginationConstants.itemsPerPage : pagingParams.itemsPerPage;
        vm.openManager = openManager;

        vm.search = search;
        vm.pressEnter = pressEnter;
        vm.prefilter=prefilter;

        vm.filters = {
            ownerId: pagingParams.ownerId,
            name: pagingParams.name,
            deleted: pagingParams.deleted,
        }

        loadAll();

        function loadAll () {
            Space.query($.extend({}, vm.filters, {
                page: pagingParams.page - 1,
                itemsPerPage: parseInt(vm.itemsPerPage),
                size: vm.itemsPerPage,
                sort: sort()
            }), onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.spaces = data;
                vm.page = pagingParams.page;
                console.log(vm.spaces)
            }

        }

        function prefilter(data) {
            vm.filters = data;
            vm.transition();
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current,$.extend({}, vm.filters, {
                page: vm.page,
                itemsPerPage: parseInt(vm.itemsPerPage),
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            }));
        }

        function search(){
            vm.transition();
        }

        function pressEnter(event){
            if(event.keyCode === 13){
                search();
            }
        }

        function openManager(space){
        	Space.generateSuperAdminSpaceToken({id: space.id }, onSaveSuccessOpenManager, onError);
        }

        function onSaveSuccessOpenManager(responseSuperAdminSpaceToken){
        	window.open(responseSuperAdminSpaceToken.managerUrl,'_manager');
        }
    }
})();
