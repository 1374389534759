(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventBookingCommentController',TropEventBookingCommentController);

    TropEventBookingCommentController.$inject = ['$uibModalInstance', 'entity', 'TropEventBooking'];

    function TropEventBookingCommentController($uibModalInstance, entity, TropEventBooking) {
        var vm = this;

        vm.tropEventBooking = entity;
        vm.clear = clear;
        vm.confirmComment = confirmComment;
        vm.comment = "";

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmComment (id, comment) {
            TropEventBooking.comment({id: id, comment : comment},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
