(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropUserDeleteController',TropUserDeleteController);

    TropUserDeleteController.$inject = ['$uibModalInstance', 'entity', 'TropUser'];

    function TropUserDeleteController($uibModalInstance, entity, TropUser) {
        var vm = this;

        vm.tropUser = entity;
        vm.ownerId = vm.tropUser.id;
        vm.clear = clear;
        vm.confirmDeleteUser = confirmDeleteUser;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDeleteUser (id) {
            TropUser.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
