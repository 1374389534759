(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropEventBookingDetailController', TropEventBookingDetailController);

    TropEventBookingDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TropEventBooking'];

    function TropEventBookingDetailController($state, $scope, $rootScope, $stateParams, previousState, entity, TropEventBooking) {
        var vm = this;

        vm.tropEventBooking = entity;
        vm.sendEmail = sendEmail;
        vm.previousState = previousState.name;
        vm.filterSearch = "";
        vm.search = search;

        var unsubscribe = $rootScope.$on('tropadminApp:tropEventBookingUpdate', function(event, result) {
            vm.tropEventBooking = result;
        });
        $scope.$on('$destroy', unsubscribe);
        function sendEmail() {
            if (confirm("Souhaitez-vous envoyer une confirmation de mail ?")) {
                TropEventBooking.sendEmail({id: vm.tropEventBooking.id});
            }

        }

        function search() {
            $state.go('trop-event-booking', {
                page: 1,
                sort: 'id,desc',
                search: vm.filterSearch,
            });
        }
    }
})();
