(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('SpaceDetailController', SpaceDetailController);

    SpaceDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Space', 'TropEvent'];

    function SpaceDetailController($scope, $state, $rootScope, $stateParams, previousState, entity, Space, TropEvent) {
        var vm = this;

        vm.space = entity;
        vm.previousState = previousState.name;
        vm.openManager = openManager;
        vm.nbEvents = null;
        vm.addSubscriptionOption = addSubscriptionOption;
        vm.deleteSubscriptionOption = deleteSubscriptionOption;
        vm.subscriptionOptionTypeSelected = null;
        vm.subscriptionOptionDateEndIncluded = null;

        TropEvent.statsEventsBySpace({id: vm.space.id }, function(data){
              vm.nbEvents=data.nbEvents;
        });

        allSubscriptionOptionsType();

        var unsubscribe = $rootScope.$on('tropadminApp:spaceUpdate', function(event, result) {
            vm.space = result;
        });
        $scope.$on('$destroy', unsubscribe);


        function openManager(space){
        	Space.generateSuperAdminSpaceToken({id: space.id }, onSaveSuccessOpenManager, onError);
        }

        function onSaveSuccessOpenManager(responseSuperAdminSpaceToken){
        	window.open(responseSuperAdminSpaceToken.managerUrl,'_manager');
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function allSubscriptionOptionsType(){
        	Space.getAllSubscriptionOptionsType({},onSuccessAllSubscriptionOptionsType,onError);
        }

        function onSuccessAllSubscriptionOptionsType(data){
        	vm.subscriptionOptionsType=data;
        }

        function addSubscriptionOption(){
        	Space.addSubscriptionOption({
                id: vm.space.id,
                option:vm.subscriptionOptionTypeSelected,
                dateEndIncluded:vm.subscriptionOptionDateEndIncluded},onSuccessAddSubscriptionOption,onError);
        }

        function onSuccessAddSubscriptionOption() {
            $state.go($state.current, {}, {reload: true});
        }

        function deleteSubscriptionOption(subscriptionOptionType) {
            Space.deleteSubscriptionOption({
                id: vm.space.id,
                type: subscriptionOptionType.option
            }, onSuccessDeleteSubscriptionOption, onError);
        }


        function onSuccessDeleteSubscriptionOption() {
            $state.go($state.current, {}, {reload: true});
        }
    }
})();
