(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('StatService', StatService);

    StatService.$inject = ['$resource', 'DateUtils'];

    function StatService ($resource, DateUtils) {
        var resourceUrl =  'tropevent-api-v1/admin/stats';

        return $resource(resourceUrl, {}, {
            'days':{
            	url:'tropevent-api-v1/admin/stats/days'
            	,method:'GET'
            	, isArray: true
            }
        });
    }
})();
