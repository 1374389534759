(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('Gallery', Gallery);

    Gallery.$inject = ['$resource', 'DateUtils'];

    function Gallery ($resource, DateUtils) {
        var resourceUrl =  'tropevent-api-v1/admin/galleries/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateCreation = DateUtils.convertLocalDateFromServer(data.dateCreation);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateCreation = DateUtils.convertLocalDateToServer(copy.dateCreation);
                    if( typeof copy.tags === 'string' ) {
                    	copy.tags = copy.tags.split(',');
                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateCreation = DateUtils.convertLocalDateToServer(copy.dateCreation);
                    copy.tags = copy.tags.split(',');
                    return angular.toJson(copy);
                }
            },
            'imageTargetNames': {
                method: 'GET',
                url: 'tropevent-api-v1/admin/imageTargetNames',
                isArray: true
            }
        });
    }
})();
