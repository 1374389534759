(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('TropUserDetailController', TropUserDetailController);

    TropUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TropUser', 'TropEventBooking', 'ParseLinks'];

    function TropUserDetailController($scope, $rootScope, $stateParams, previousState, entity, TropUser, TropEventBooking, ParseLinks) {
        var vm = this;
        vm.sendEmail = sendEmail;
        vm.tropUser = entity;
        vm.nbBooking = null;
        vm.previousState = previousState.name;

        TropEventBooking.count({userId: vm.tropUser.id}).$promise.then(function (data){
            vm.nbBooking=data.count;
            console.log(vm.nbBooking);
        });

        var unsubscribe = $rootScope.$on('tropadminApp:tropUserUpdate', function(event, result) {
            vm.tropUser = result;
        });
        function sendEmail() {
            if (confirm("Souhaitez-vous envoyer une confirmation de mail ?")) {
                TropUser.sendEmail({id: vm.tropUser.id});
            }
        }
        $scope.$on('$destroy', unsubscribe);
    }
})();
