(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropEventSyncLog', TropEventSyncLog);

    TropEventSyncLog.$inject = ['$resource', 'DateUtils'];

    function TropEventSyncLog ($resource, DateUtils) {
        var resourceUrl =  'tropevent-loader/admin/trop-event-sync-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
            ,'pause':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/pause'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'resume':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/resume'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'trigger':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/trigger'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'clear':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/clear'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'restartError':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/restartError'
            	,method:'PUT'
            	,hasBody:false
            }
            ,'status':{
            	url:'tropevent-loader/admin/trop-event-sync-logs/status'
            	,method:'GET'
            }
        });
    }
})();
