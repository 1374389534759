(function() {
    'use strict';
    angular
        .module('tropadminApp')
        .factory('TropEventLoaderLog', TropEventLoaderLog);

    TropEventLoaderLog.$inject = ['$resource', 'DateUtils'];

    function TropEventLoaderLog ($resource, DateUtils) {
        var resourceUrl =  'tropevent-loader/admin/trop-event-loader-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
