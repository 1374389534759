(function () {
    'use strict';

    angular
        .module('tropadminApp')
        .controller('MessagingMailDetailController', MessagingMailDetailController);

 MessagingMailDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MessagingMail'];

    function MessagingMailDetailController($scope, $rootScope, $stateParams, previousState, entity, MessagingMail) {
        var vm = this;

        vm.messagingMail = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('tropadminApp:messagingMailUpdate', function(event, result) {
            vm.messagingMail = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
