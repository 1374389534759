(function() {
    'use strict';

    angular
        .module('tropadminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('stat-search-criteria', {
            parent: 'stats',
            url: '/stat-search-criteria?page&itemsPerPage&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'StatSearchCriteria'
            },
            views: {
                'content@': {
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria.html',
                    controller: 'StatSearchCriteriaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                itemsPerPage: {
                    value: '20',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        itemsPerPage: $stateParams.itemsPerPage,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
            }
        })
        .state('stat-search-criteria-detail', {
            parent: 'stat-search-criteria',
            url: '/stat-search-criteria/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'StatSearchCriteria'
            },
            views: {
                'content@': {
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria-detail.html',
                    controller: 'StatSearchCriteriaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'StatSearchCriteria', function($stateParams, StatSearchCriteria) {
                    return StatSearchCriteria.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'stat-search-criteria',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('stat-search-criteria-detail.edit', {
            parent: 'stat-search-criteria-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria-dialog.html',
                    controller: 'StatSearchCriteriaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['StatSearchCriteria', function(StatSearchCriteria) {
                            return StatSearchCriteria.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('stat-search-criteria.new', {
            parent: 'stat-search-criteria',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria-dialog.html',
                    controller: 'StatSearchCriteriaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                username: null,
                                date: null,
                                what: null,
                                where: null,
                                when: null,
                                nbResults: null,
                                userAgent: null,
                                acceptLanguage: null,
                                referer: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('stat-search-criteria', null, { reload: 'stat-search-criteria' });
                }, function() {
                    $state.go('stat-search-criteria');
                });
            }]
        })
        .state('stat-search-criteria.edit', {
            parent: 'stat-search-criteria',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria-dialog.html',
                    controller: 'StatSearchCriteriaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['StatSearchCriteria', function(StatSearchCriteria) {
                            return StatSearchCriteria.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('stat-search-criteria', null, { reload: 'stat-search-criteria' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('stat-search-criteria.delete', {
            parent: 'stat-search-criteria',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/stats/stat-search-criteria/stat-search-criteria-delete-dialog.html',
                    controller: 'StatSearchCriteriaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['StatSearchCriteria', function(StatSearchCriteria) {
                            return StatSearchCriteria.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('stat-search-criteria', null, { reload: 'stat-search-criteria' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
